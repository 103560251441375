import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const Hero = ({ children }) => {
    const data = useStaticQuery(
        graphql`
            query {
                desktop: file(relativePath: { eq: "airplane-flying.jpg" }) {
                    childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      backgroundColor={`#040e18`}
      style={{
          width: '100%',
          backgroundPosition: '50% 20%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          top: '-16px',
          zIndex: '0',
          height: 'calc(100vh - 57px)'
      }}
    >
      {children}
    </BackgroundImage>
  )
}

export default Hero