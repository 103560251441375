import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import CTA from "../components/cta"
import { Container } from "react-bootstrap"

const IndexPage = () => (
  <Layout>
    <SEO title="Web Design That Gets Results" />
    <Hero>
      <h1 className="primary-blue text-center mx-3" id="main-page-hero-text"><Link to="/services/web-design/">Web Design</Link> That Takes Your Business To New Heights.</h1>
    </Hero>
    <Container className="py-5">
      <div className='text-center my-5'>
        <h2 className='mb-4'>What We're All About</h2>
        <p className="px-4">Esseltine Marketing was founded to help businesses in the digital age.  We noticed and understand that previous marketing platforms such as newsprint just don’t get the business like they used to! As a result, we developed our services as individual tools to generate business from the internet.  We truly do not believe that one solution fits all clients.  Instead, we look at how we can improve each of our clients’ campaigns' individually.  The benefit to this is we take you from where your business is now to where you want your business to be can much more effectively.</p>
        <p className="px-4">Our typical project consists of a mix of all of our services but it is always key that we establish where to begin with your digital marketing.  Our web services typically begin with strong branding and great <Link to='/services/web-design'>web design</Link>.  This process could include things such as graphic and logo design or even comprehensive digital branding packages.  Next, it is crucial to drive traffic to the client’s site.  This is accomplished through a mixture of <Link to='services/seo'>search engine optimization (seo)</Link>, social media, and email marketing campaigns.  In the end, our clients have a large digital footprint for their customers to find and purchase from.</p>
        <p className="px-4">Unlike many other agencies that focus on building themselves a pretty portfolio, our only goal is to <strong>get you results.</strong></p>
      </div>
      </Container>
      <CTA header="Ready to build your digital success story?" button="Get in Touch!" link="/contact/"/>
  </Layout>
)

export default IndexPage
